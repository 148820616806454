@import '../../../../scss/theme-bootstrap';

// new for 2018 Creative Refresh

.product-badge {
  font-family: $font--futura-demi;
  font-size: 15px;
  text-transform: uppercase;
  &__text {
    text-align: center;
  }
  &__icon {
    height: auto;
    margin: 10px auto 0;
    .mpp,
    .product-grid__item & {
      width: 80px;
      @include breakpoint($medium-up) {
        width: 70px;
      }
    }
    .spp-page & {
      width: 80px;
    }
  }
  &__image {
    display: none;
    &.badge-misc-flag--107,
    &.badge-misc-flag--25,
    &.badge-misc-flag--22,
    &.badge-misc-flag--106,
    &.badge-misc-flag--52,
    &.badge-misc-flag--97,
    &.badge-misc-flag--13,
    &.badge-misc-flag--96,
    &.badge-misc-flag--167,
    &.badge-misc-flag--168,
    &.badge-misc-flag--169,
    &.badge-misc-flag--170,
    &.badge-misc-flag--171,
    &.badge-misc-flag--172,
    &.badge-misc-flag--173,
    &.badge-misc-flag--174,
    &.badge-misc-flag--175,
    &.badge-misc-flag--176,
    &.badge-misc-flag--177,
    &.badge-misc-flag--178,
    &.badge-misc-flag--179,
    &.badge-misc-flag--180,
    &.badge-misc-flag--181,
    &.badge-misc-flag--182,
    &.badge-misc-flag--183,
    &.badge-misc-flag--184,
    &.badge-misc-flag--185,
    &.badge-misc-flag--186,
    &.badge-misc-flag--187,
    &.badge-misc-flag--188,
    &.badge-misc-flag--189,
    &.badge-misc-flag--190,
    &.badge-misc-flag--191 {
      display: block;
    }
  }
}
